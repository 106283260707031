export const setupShareFunctionality = () => {
  console.log('Share functionality setup starting...');
  const shareButtons = document.querySelectorAll('.post-single-box > .like-comment-share .share-button');
  
  const socialIcons = {
    facebook: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 24 24">
      <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
    </svg>`,
    twitter: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 24 24">
      <path d="M14.355 12.445L23.547 2h-2.131l-7.981 9.113L7.715 2H0l8.481 12.876L0 24h2.131l7.981-9.113L16.285 24H24l-8.481-12.876zm-2.262 2.584-1.162-1.662-6.094-8.717h2.577l4.923 7.042 1.162 1.662 6.094 8.717h-2.577l-4.923-7.042z"/>
    </svg>`,
    linkedin: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 24 24">
      <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
      <rect x="2" y="9" width="4" height="12"></rect>
      <circle cx="4" cy="4" r="2"></circle>
    </svg>`,
    copy: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 24 24">
      <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
    </svg>`
  };
  
  shareButtons.forEach(button => {
    button.addEventListener('click', (event) => {
      event.preventDefault();
      
      const listing = button.closest('.post-single-box');
      const listingUrl = listing.querySelector('a[href^="/listings/"]').href;
      const description = listing.querySelector('.description')?.textContent.trim() || '';
      
      const shareMenu = document.createElement('div');
      shareMenu.className = 'share-menu dropdown-menu show';
      shareMenu.style.position = 'absolute';
      shareMenu.style.transform = 'translate3d(0px, 40px, 0px)';

      // Check if dark theme is active
      const isDarkTheme = document.documentElement.classList.contains('dark-ui');
      
      // Set theme-specific styles
      shareMenu.style.backgroundColor = isDarkTheme ? '#2c2c2c' : '#ffffff';
      shareMenu.style.border = isDarkTheme ? '1px solid #404040' : '1px solid #e0e0e0';
      shareMenu.style.borderRadius = '8px';
      shareMenu.style.padding = '8px 0';
      shareMenu.style.zIndex = '1000';
      shareMenu.style.boxShadow = isDarkTheme ? '0 2px 5px rgba(0,0,0,0.3)' : '0 2px 5px rgba(0,0,0,0.1)';
      
      const shareOptions = [
        {
          name: 'Facebook',
          icon: socialIcons.facebook,
          url: `https://www.facebook.com/dialog/share?app_id=1582452919049343&display=popup&href=${encodeURIComponent(listingUrl)}&redirect_uri=${encodeURIComponent(listingUrl)}`
        },
        {
          name: 'X (Twitter)',
          icon: socialIcons.twitter,
          url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(listingUrl)}&text=${encodeURIComponent(description)}`
        },
        {
          name: 'LinkedIn',
          icon: socialIcons.linkedin,
          url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(listingUrl)}&title=${encodeURIComponent(description)}&source=${encodeURIComponent(window.location.origin)}`
        },
        {
          name: 'Copy Link',
          icon: socialIcons.copy,
          action: () => {
            navigator.clipboard.writeText(listingUrl).then(() => {
              const toast = createToast();
              toast.innerHTML = `
                <div class="toast-body">
                  Link copied to clipboard!
                </div>
              `;
              document.body.appendChild(toast);
              setTimeout(() => toast.remove(), 3000);
            });
          }
        }
      ];

      const createToast = () => {
        const toast = document.createElement('div');
        toast.className = 'toast show position-fixed bottom-0 end-0 m-3';
        toast.setAttribute('role', 'alert');
        toast.style.backgroundColor = isDarkTheme ? '#2c2c2c' : '#ffffff';
        toast.style.color = isDarkTheme ? '#ffffff' : '#2c2c2c';
        toast.style.border = isDarkTheme ? '1px solid #404040' : '1px solid #e0e0e0';
        toast.style.boxShadow = isDarkTheme ? '0 2px 5px rgba(0,0,0,0.3)' : '0 2px 5px rgba(0,0,0,0.1)';
        toast.style.borderRadius = '8px';
        return toast;
      };

      const openShareWindow = (url) => {
        const width = 550;
        const height = 450;
        const left = (window.screen.width / 2) - (width / 2);
        const top = (window.screen.height / 2) - (height / 2);
        window.open(
          url,
          'share',
          `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
        );
      };
      
      shareOptions.forEach(option => {
        const link = document.createElement('a');
        link.className = 'd-flex align-items-center gap-2 dropdown-item';
        link.style.color = isDarkTheme ? '#ffffff' : '#2c2c2c';
        link.style.padding = '8px 16px';
        link.style.cursor = 'pointer';
        link.style.textDecoration = 'none';
        link.style.fontSize = '14px';
        
        link.addEventListener('mouseover', () => {
          link.style.backgroundColor = isDarkTheme ? '#404040' : '#f5f5f5';
        });
        link.addEventListener('mouseout', () => {
          link.style.backgroundColor = 'transparent';
        });
        
        link.innerHTML = `
          <span class="icon" style="display: flex; align-items: center;">${option.icon}</span>
          <span>${option.name}</span>
        `;
        
        if (option.url) {
          link.href = '#';
          link.addEventListener('click', (e) => {
            e.preventDefault();
            openShareWindow(option.url);
            shareMenu.remove();
          });
        } else if (option.action) {
          link.href = '#';
          link.addEventListener('click', (e) => {
            e.preventDefault();
            option.action();
            shareMenu.remove();
          });
        }
        
        shareMenu.appendChild(link);
      });
      
      const existingMenu = document.querySelector('.share-menu');
      if (existingMenu) existingMenu.remove();
      
      button.parentNode.appendChild(shareMenu);
      
      const closeMenu = (e) => {
        if (!shareMenu.contains(e.target) && !button.contains(e.target)) {
          shareMenu.remove();
          document.removeEventListener('click', closeMenu);
        }
      };
      
      setTimeout(() => {
        document.addEventListener('click', closeMenu);
      }, 0);
    });
  });
};

window.setupShareFunctionality = setupShareFunctionality;