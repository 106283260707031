import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

const EnhancedDistanceFilter = () => {
  const [distance, setDistance] = useState(6000);
  const [city, setCity] = useState('');
  const [userHasLocation, setUserHasLocation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  useEffect(() => {
    checkUserAddress();
  }, []);

  const checkUserAddress = async () => {
    try {
      const response = await fetch('/api/user/has_address', {
        headers: {
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      });
      
      if (!response.ok) throw new Error('Failed to check address');
      
      const data = await response.json();
      setUserHasLocation(data.hasAddress);
      
      if (!data.hasAddress) {
        console.log('User location not available:', data.message);
      }
    } catch (error) {
      console.error('Error checking user address:', error);
      setUserHasLocation(false);
    }
  };

  const reinitializeFunctionalities = () => {
    console.log('Starting reinitialization...');
    const functionsToReinitialize = [
      { name: 'setupLikeFunctionality', func: window.setupLikeFunctionality },
      { name: 'setupCommentFunctionality', func: window.setupCommentFunctionality },
      { name: 'setupNotificationFunctionality', func: window.setupNotificationFunctionality },
      { name: 'setupMessageDropdownFunctionality', func: window.setupMessageDropdownFunctionality },
      { name: 'setupStoriesFunctionality', func: window.setupStoriesFunctionality },
      { name: 'setupShareFunctionality', func: window.setupShareFunctionality }
    ];
  
    functionsToReinitialize.forEach(({ name, func }) => {
      if (typeof func === 'function') {
        try {
          console.log(`Reinitializing ${name}...`);
          func();
        } catch (error) {
          console.error(`Error reinitializing ${name}:`, error);
        }
      }
    });
  };

  const updateListings = async (newDistance, newCity) => {
    setIsLoading(true);
    const spinner = document.getElementById('infinite-scroll-spinner');
    if (spinner) spinner.style.display = 'block';

    try {
      // Update the filters
      const filterResponse = await fetch('/api/listings/filter_distance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
          'Accept': 'application/json'
        },
        body: JSON.stringify({ 
          distance: newDistance,
          city: newCity || null  // Explicitly send null when city is empty
        })
      });

      if (!filterResponse.ok) {
        throw new Error('Failed to update filters');
      }

      // Fetch updated listings
      const listingsResponse = await fetch('/feed?filter_distance=true', {
        headers: {
          'Accept': 'text/javascript',
          'X-Requested-With': 'XMLHttpRequest'
        }
      });

      if (!listingsResponse.ok) {
        throw new Error('Failed to fetch listings');
      }

      const text = await listingsResponse.text();
      
      const script = document.createElement('script');
      script.text = text;
      document.body.appendChild(script);
      document.body.removeChild(script);

      setTimeout(reinitializeFunctionalities, 250);

    } catch (error) {
      console.error('Error updating listings:', error);
      const container = document.getElementById('listings-container');
      if (container) {
        container.innerHTML = `
          <div class="alert alert-danger">
            <p>Error loading listings. Please try again.</p>
            <small>If the problem persists, try refreshing the page.</small>
          </div>
        `;
      }
    } finally {
      setIsLoading(false);
      if (spinner) spinner.style.display = 'none';
    }
  };

  const handleSliderChange = (e) => {
    setDistance(parseInt(e.target.value));
  };

  const handleSliderRelease = (e) => {
    const newDistance = parseInt(e.target.value);
    updateListings(newDistance, city);
  };

  const handleCityChange = (e) => {
    const newCity = e.target.value;
    setCity(newCity);
    
    // Debounce the city search
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    
    const timeoutId = setTimeout(() => {
      // If city is empty, pass null or undefined to ensure filter is removed
      updateListings(distance, newCity || null);
    }, 500);
    
    setDebounceTimeout(timeoutId);
  };

  if (!userHasLocation) {
    return (
      <div className="distance-filter">
        <div className="mb-4">
          <h6 className="d-inline-flex">Location Filters</h6>
        </div>
        <p className="text-sm text-gray-500">
          Please add your address in your profile settings to use the distance filter.
        </p>
      </div>
    );
  }

  return (
    <div className="distance-filter">
      <div className="mb-4">
        <h6 className="d-inline-flex">
          Location Filters&nbsp;
          {isLoading && <span className="ml-2">Loading...</span>}
        </h6>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 mb-5">
          <label htmlFor="city-filter" className="text-sm font-medium">City</label>
          <input
            id="city-filter"
            type="text"
            value={city}
            onChange={handleCityChange}
            placeholder="Enter city name"
            className="w-full px-3 py-2 border rounded-md"
            disabled={isLoading}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="distance-slider" className="text-sm font-medium">Distance</label>
          <input
            id="distance-slider"
            type="range"
            min="1"
            max="6000"
            value={distance}
            onChange={handleSliderChange}
            onMouseUp={handleSliderRelease}
            onTouchEnd={handleSliderRelease}
            className="w-full"
            disabled={isLoading}
          />
          <div className="flex justify-between">
            <span className="text-sm">{distance} miles</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const setupDistanceFilter = () => {
  console.log('Setting up enhanced distance filter');
  const container = document.getElementById('distance-filter');
  if (container) {
    ReactDOM.render(<EnhancedDistanceFilter />, container);
    console.log('Enhanced distance filter mounted');
  } else {
    console.warn('Distance filter container not found');
  }
};

window.setupDistanceFilter = setupDistanceFilter;

export { setupDistanceFilter };
export default EnhancedDistanceFilter;